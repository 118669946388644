import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, IconButton, Typography
} from '@mui/material';
import { Assignment, Edit, Delete, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './table.css';
import { useEffect } from 'react';

const JobTable = ({ filteredJobs,handleAssignOpenEdit, jobAssignments, handleAssignOpen, handleEditOpen, handleDeleteOpen }) => {
    const nav = useNavigate();

    // Sort jobs by createdAt in descending order
   useEffect(()=>{

   },[
   ])
    return (
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Job Title</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Assigned To</TableCell>
                        <TableCell>Assignment Status</TableCell>
                        <TableCell>Job Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredJobs.length > 0 ? (
                        filteredJobs.map((job) => {
                            const jobstatus = job.assignedstatus
                                ? (job.jobstatus ? 'Complete' : 'Processing')
                                : (job.jobstatus ? 'Complete' : 'Pending');
                            return (
                                <TableRow
                                    key={job.id}
                                    className="job-table-row"
                                    sx={{
                                        backgroundColor: job.assignedstatus ? '#e0f7fa' : '#ffebee',
                                        '&:hover': {
                                            backgroundColor: job.assignedstatus ? '#b2ebf2' : '#ffcdd2'
                                        }
                                    }}
                                >
                                    <TableCell>{job.job_title}</TableCell>
                                    <TableCell>{job.location}</TableCell>
                                    <TableCell>{new Date(job.createdAt).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        {job.assignedstatus ? 
                                            jobAssignments.find(assignment => assignment.job_id === job.id)?.worker.username || 'Unknown' 
                                            : 'Not Assigned'}
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={job.assignedstatus ? 'Assigned' : 'Not Assigned'}
                                            color={job.assignedstatus ? 'success' : 'error'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={jobstatus}
                                            color={
                                                jobstatus === 'Complete' ? 'success'
                                                    : jobstatus === 'Processing' ? 'warning'
                                                    : 'default'
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {!job.assignedstatus && (
                                               <IconButton onClick={() => handleAssignOpen(job)} disabled={job.assignedstatus}>
                                               <Assignment />
                                           </IconButton>
                                        )}
                                           {job.assignedstatus && (
                                               <IconButton onClick={() => handleAssignOpenEdit(job)} disabled={job.jobstatus} >
                                               <Assignment />
                                           </IconButton>
                                        )}
                                  
                                        <IconButton onClick={() => handleEditOpen(job)}>
                                            <Edit />
                                        </IconButton>
                                        {/* <IconButton onClick={() => handleDeleteOpen(job.id)}>
                                            <Delete />
                                        </IconButton> */}
                                        {jobstatus === 'Complete' && (
                                            <IconButton onClick={() => {
                                                nav('/feedback', { state: { JobId: job.id } });
                                            }}>
                                                <Visibility />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography>No jobs available</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default JobTable;

