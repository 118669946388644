// import React, { useEffect, useState } from 'react';
// import { gapi } from 'gapi-script';
// import { Button, Typography, Modal, Box } from '@mui/material';
// import axios from 'axios';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import moment from 'moment';

// const localizer = momentLocalizer(moment);

// const JobAssignmentsCalendar = ({ token }) => {
//     const [events, setEvents] = useState([]);
//     const [selectedEvent, setSelectedEvent] = useState(null);
//     const [open, setOpen] = useState(false);
//     const [assignedJobs, setAssignedJobs] = useState([]);

    // useEffect(() => {
    //     function start() {
    //         gapi.client.init({
    //             apiKey: process.env.APIKEY,
    //             discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    //             clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //             scope: "https://www.googleapis.com/auth/calendar",
    //         }).then(() => {
    //             console.log('GAPI client initialized.');

    //             const authInstance = gapi.auth2.getAuthInstance();
    //             if (authInstance.isSignedIn.get()) {
    //                 console.log('User is already signed in.');
    //                 fetchAssignedJobs();
    //                 fetchEvents();
    //             } else {
    //                 handleAuthClick();
    //             }
    //         }).catch((error) => {
    //             console.error('Error initializing Google API:', error);
    //         });
    //     }

    //     gapi.load("client:auth2", start);
    // }, []);

    // const fetchAssignedJobs = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/job-assigned`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`, // Include token in headers
    //             },
    //         });
    //         const jobs = response.data;

    //         // Filter jobs where jobstatus is true
    //         jobs.forEach(job => {
    //             if (job.job.jobstatus === true) {
    //                 // Convert job_id to string and delete event
    //                 deleteEventByJobId(String(job.job_id));
    //             }
    //         });

    //         setAssignedJobs(jobs);
    //         console.log("Fetched jobs:", jobs);
    //     } catch (error) {
    //         console.error("Error fetching jobs:", error);
    //     }
    // };

    // const deleteEventByJobId = async (jobId) => {
    //     try {
    //         const response = await gapi.client.calendar.events.list({
    //             calendarId: 'primary',
    //             maxResults: 100,
    //             singleEvents: true,
    //             orderBy: 'startTime',
    //         });

    //         const events = response.result.items;
    //         let eventToDelete = null;

    //         // Find the event that matches the jobId
    //         for (const event of events) {
    //             if (event.description.includes(`Job ID: ${jobId}`)) {
    //                 eventToDelete = event;
    //                 break;
    //             }
    //         }

    //         if (eventToDelete) {
    //             await gapi.client.calendar.events.delete({
    //                 calendarId: 'primary',
    //                 eventId: eventToDelete.id,
    //             });
    //             console.log(`Deleted event for Job ID: ${jobId}`);
    //             await fetchEvents(); // Refresh the events list
    //         } else {
    //             console.log(`No event found for Job ID: ${jobId}`);
    //         }
    //     } catch (error) {
    //         console.error('Error deleting event by Job ID:', error);
    //     }
    // };

    // const fetchEvents = async () => {
    //     try {
    //         const response = await gapi.client.calendar.events.list({
    //             calendarId: 'primary',
    //             timeMin: new Date(0).toISOString(),
    //             maxResults: 100,
    //             singleEvents: true,
    //             orderBy: 'startTime',
    //         });

    //         let allEvents = response.result.items;
    //         let pageToken = response.result.nextPageToken;

    //         while (pageToken) {
    //             const nextPageResponse = await gapi.client.calendar.events.list({
    //                 calendarId: 'primary',
    //                 timeMin: new Date(0).toISOString(),
    //                 maxResults: 100,
    //                 singleEvents: true,
    //                 orderBy: 'startTime',
    //                 pageToken: pageToken,
    //             });

    //             allEvents = [...allEvents, ...nextPageResponse.result.items];
    //             pageToken = nextPageResponse.result.nextPageToken;
    //         }

    //         setEvents(allEvents);
    //         console.log('Fetched events:', allEvents);
    //     } catch (error) {
    //         console.error('Error fetching events:', error);
    //     }
    // };

    // const handleAuthClick = async () => {
    //     try {
    //         await gapi.auth2.getAuthInstance().signIn();
    //         await fetchEvents();
    //     } catch (error) {
    //         console.error('Error signing in:', error);
    //     }
    // };

    // const handleSignOut = () => {
    //     gapi.auth2.getAuthInstance().signOut();
    // };

    // const handleEventClick = (event) => {
    //     setSelectedEvent(event);
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

//     return (
//         <div>
//             <Button onClick={handleAuthClick}>Sign In with Google</Button>
//             <Button onClick={handleSignOut}>Sign Out</Button>
//             <Typography variant="h6">Events:</Typography>

//             <Calendar
//                 localizer={localizer}
//                 events={events.map(event => ({
//                     title: event.summary,
//                     start: new Date(event.start.dateTime || event.start.date),
//                     end: new Date(event.end.dateTime || event.end.date),
//                     allDay: false,
//                     assignedTo: event.description
//                 }))}
//                 startAccessor="start"
//                 endAccessor="end"
//                 style={{ height: 500, margin: '50px' }}
//                 onSelectEvent={handleEventClick}
//             />

//             {/* Job details modal */}
//             <Modal open={open} onClose={handleClose}>
//                 <Box sx={{
//                     position: 'absolute',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     width: { xs: '90%', sm: 400 },
//                     bgcolor: '#ffffff',
//                     border: '1px solid #ccc',
//                     borderRadius: 2,
//                     boxShadow: 3,
//                     p: 4,
//                     overflow: 'auto',
//                 }}>
//                     {selectedEvent && (
//                         <>
//                             <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
//                                 Event Details
//                             </Typography>
//                             <Typography>
//                                 <strong>Job Title:</strong> {selectedEvent.title}
//                             </Typography>
//                             <Typography>
//                                 <strong>Assigned To:</strong> {selectedEvent.assignedTo}
//                             </Typography>
//                             <Typography>
//                                 <strong>Start:</strong> {new Date(selectedEvent.start || selectedEvent.start).toString()}
//                             </Typography>
//                         </>
//                     )}
//                 </Box>
//             </Modal>
//         </div>
//     );
// };

// export default JobAssignmentsCalendar;
// import React, { useEffect, useState } from 'react';
// import { gapi } from 'gapi-script';
// import { Button, Typography, Modal, Box } from '@mui/material';
// import axios from 'axios';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import moment from 'moment';
// import EventIcon from '@mui/icons-material/Event';
// import BlockIcon from '@mui/icons-material/Block';
// import { useLocation,useNavigate} from 'react-router-dom'

// const localizer = momentLocalizer(moment);

// const JobAssignmentsCalendar = ({ token }) => {
//     const [events, setEvents] = useState([]);
//     const [selectedEvent, setSelectedEvent] = useState(null);
//     const [open, setOpen] = useState(false);
//     const [assignedJobs, setAssignedJobs] = useState([]);
//     const nav = useNavigate()
//     useEffect(() => {
//         function start() {
//             gapi.client.init({
//                 apiKey: process.env.APIKEY,
//                 discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//                 clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
//                 scope: "https://www.googleapis.com/auth/calendar",
//             }).then(() => {
//                 console.log('GAPI client initialized.');

//                 const authInstance = gapi.auth2.getAuthInstance();
//                 if (authInstance.isSignedIn.get()) {
//                     console.log('User is already signed in.');
//                     fetchAssignedJobs();
//                     fetchEvents();
                   
//                 } else {
//                     handleAuthClick();
//                 }
//             }).catch((error) => {
//                 console.error('Error initializing Google API:', error);
//             });
//         }

//         gapi.load("client:auth2", start);
//     }, []);

//     const fetchAssignedJobs = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/job-assigned`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             const jobs = response.data;

//             jobs.forEach(job => {
//                 // console.log(job)
//                 if (job.job.jobstatus === true) {
//                     // console.log('try to deleted')
//                     deleteEventByJobId(String(job.job_id));
//                 }
//             });

//             setAssignedJobs(jobs);
//             // console.log("Fetched jobs:", jobs);
//         } catch (error) {
//             console.error("Error fetching jobs:", error);
//         }
//     };

//     const deleteEventByJobId = async (jobId) => {
//         try {
//             const response = await gapi.client.calendar.events.list({
//                 calendarId: 'primary',
//                 maxResults: 100,
//                 singleEvents: true,
//                 orderBy: 'startTime',
//             });
    
//             const events = response.result.items;
//             const eventsToDelete = events.filter(event => event.description.includes(`Job ID: ${jobId}`));
    
//             if (eventsToDelete.length > 0) {
//                 for (const event of eventsToDelete) {
//                     await gapi.client.calendar.events.delete({
//                         calendarId: 'primary',
//                         eventId: event.id,
//                     });
//                     // console.log(`Deleted event for Job ID: ${jobId}, Event: ${event.summary}`);
//                 }
//                 await fetchEvents(); // Refresh the event list after deletions
//             } else {
//                 // console.log(`No events found for Job ID: ${jobId}`);
//             }
//         } catch (error) {
//             console.error('Error deleting event by Job ID:', error);
//         }
//     };
//     // const deleteAllEvents = async () => {
//     //     try {
//     //         const response = await gapi.client.calendar.events.list({
//     //             calendarId: 'primary',
//     //             maxResults: 100,
//     //             singleEvents: true,
//     //             orderBy: 'startTime',
//     //         });
    
//     //         const events = response.result.items;
    
//     //         if (events.length > 0) {
//     //             for (const event of events) {
//     //                 await gapi.client.calendar.events.delete({
//     //                     calendarId: 'primary',
//     //                     eventId: event.id,
//     //                 });
//     //                 console.log(`Deleted event: ${event.summary}`);
//     //             }
//     //             console.log('All events deleted from the calendar.');
//     //             await fetchEvents(); // Refresh the event list after deletions
//     //         } else {
//     //             console.log('No events found in the calendar.');
//     //         }
//     //     } catch (error) {
//     //         console.error('Error deleting events:', error);
//     //     }
//     // };
    
//     const fetchEvents = async () => {
//         try {
//             const response = await gapi.client.calendar.events.list({
//                 calendarId: 'primary',
//                 timeMin: new Date(0).toISOString(),
//                 maxResults: 100,
//                 singleEvents: true,
//                 orderBy: 'startTime',
//             });

//             let allEvents = response.result.items;
//             let pageToken = response.result.nextPageToken;

//             while (pageToken) {
//                 const nextPageResponse = await gapi.client.calendar.events.list({
//                     calendarId: 'primary',
//                     timeMin: new Date(0).toISOString(),
//                     maxResults: 100,
//                     singleEvents: true,
//                     orderBy: 'startTime',
//                     pageToken: pageToken,
//                 });

//                 allEvents = [...allEvents, ...nextPageResponse.result.items];
//                 pageToken = nextPageResponse.result.nextPageToken;
//             }

//             setEvents(allEvents);
//             // console.log('Fetched events:', allEvents);
//         } catch (error) {
//             console.error('Error fetching events:', error);
//         }
//     };

//     const handleAuthClick = async () => {
//         try {
//             await gapi.auth2.getAuthInstance().signIn();
//             await fetchEvents();
//         } catch (error) {
//             console.error('Error signing in:', error);
//         }
//     };

//     const handleSignOut = () => {
//         gapi.auth2.getAuthInstance().signOut();
//     };

//     const handleEventClick = (event) => {
//         setSelectedEvent(event);
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const eventStyleGetter = (event) => {
//         const style = {
//             display: 'flex',
//             alignItems: 'center',
//             padding: '5px',
//             backgroundColor: '#f0f0f0',
//             border: '1px solid #ccc',
//             borderRadius: '5px',
//         };
//         return {
//             style,
//         };
//     };

//     // Custom Day Component
//     const Day = ({ date }) => {
//         const hasEvents = events.some(event => 
//             moment(event.start).isSame(date, 'day') || moment(event.end).isSame(date, 'day')
//         );

//         return (
//             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
//                 <span>{moment(date).format('D')}</span>
//                 {hasEvents ? (
//                     <EventIcon style={{ marginTop: '5px' ,color: 'rgb(133, 144, 165)' }} />
//                 ) : (
//                     <BlockIcon style={{ marginTop: '5px', color: 'gray' }} />
//                 )}
//                 <Button variant="outlined" >
//                     Assigned
//                 </Button>
//             </div>
//         );
//     };

//     return (
//         <div>
//             {/* <Button onClick={handleAuthClick}>Sign In with Google</Button>
//             <Button onClick={handleSignOut}>Sign Out</Button> */}
            
//             <Button variant="contained" onClick={()=>{
//                             nav('/create/new/job' )
//                         }} sx={{ mb: 2, background: "#0079ae" }}>
//                             Create Job
//                         </Button>


//                         <Typography variant="h6">Events:</Typography>

//             <Calendar
//                 localizer={localizer}
//                 events={events.map(event => ({
//                     title: event.summary,
//                     start: new Date(event.start.dateTime || event.start.date),
//                     end: new Date(event.end.dateTime || event.end.date),
//                     allDay: false,
//                     assignedTo: event.description,
//                 }))}
//                 startAccessor="start"
//                 endAccessor="end"
//                 style={{ height: 500, margin: '50px' }}
//                 onSelectEvent={handleEventClick}
//                 components={{
//                     event: ({ event }) => (
//                         <div style={{ display: 'flex', alignItems: 'center',  background : '#34495e'}}>
//                             <EventIcon style={{ marginTop: '5px' ,color: 'rgb(133, 144, 165)' }} />
//                             <span>{event.title}</span>
//                         </div>
//                     ),
//                     day: Day, // Custom Day component
//                 }}
//             />

//             {/* Job details modal */}
//             <Modal open={open} onClose={handleClose}>
//                 <Box sx={{
//                     position: 'absolute',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     width: { xs: '90%', sm: 400 },
//                     bgcolor: '#ffffff',
//                     border: '1px solid #ccc',
//                     borderRadius: 2,
//                     boxShadow: 3,
//                     p: 4,
//                     overflow: 'auto',
//                 }}>
//                     {selectedEvent && (
//                         <>
//                             <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
//                                 Event Details
//                             </Typography>
//                             <Typography>
//                                 <strong>Job Title:</strong> {selectedEvent.title}
//                             </Typography>
//                             <Typography>
//                                 <strong>Assigned To:</strong> {selectedEvent.assignedTo}
//                             </Typography>
//                             <Typography>
//                                 <strong>Start:</strong> {new Date(selectedEvent.start || selectedEvent.start).toString()}
//                             </Typography>
//                         </>
//                     )}
//                 </Box>
//             </Modal>
//         </div>
//     );
// };

// export default JobAssignmentsCalendar;
// import React, { useEffect, useState } from 'react';
// import { Box, Button, Typography } from '@mui/material';
// import axios from 'axios';
// import moment from 'moment';

// const daysInMonth = (month, year) => {
//     return new Date(year, month + 1, 0).getDate();
// };

// const JobAssignmentsCalendar = ({ token }) => {
//     const [jobsByDate, setJobsByDate] = useState({});
//     const [selectedDate, setSelectedDate] = useState(null);
//     const [selectedJobs, setSelectedJobs] = useState([]);
    
//     const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
//     const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

//     useEffect(() => {
//         fetchAssignedJobs();
//     }, [currentMonth, currentYear]);

//     const fetchAssignedJobs = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/job-assigned`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });

//             const jobs = response.data;
//             const jobsGroupedByDate = {};

//             jobs.forEach(job => {
//                 const assignedDate = moment(job.assigned_date).format('YYYY-MM-DD');
//                 if (!jobsGroupedByDate[assignedDate]) {
//                     jobsGroupedByDate[assignedDate] = [];
//                 }
//                 jobsGroupedByDate[assignedDate].push(job);
//             });

//             setJobsByDate(jobsGroupedByDate);
//         } catch (error) {
//             console.error("Error fetching assigned jobs:", error);
//         }
//     };

//     const handleDateClick = (date) => {
//         const dateStr = moment(date).format('YYYY-MM-DD');
//         setSelectedJobs(jobsByDate[dateStr] || []);
//         setSelectedDate(date);
//     };

//     const renderCalendarDays = () => {
//         const days = [];
//         const totalDays = daysInMonth(currentMonth, currentYear);

//         for (let i = 1; i <= totalDays; i++) {
//             const dateStr = moment([currentYear, currentMonth, i]).format('YYYY-MM-DD');
//             const jobCount = jobsByDate[dateStr] ? jobsByDate[dateStr].length : 0;

//             days.push(
//                 <Box
//                     key={i}
//                     onClick={() => handleDateClick(new Date(currentYear, currentMonth, i))}
//                     sx={{
//                         width: '100px',
//                         height: '100px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         border: '1px solid #ddd',
//                         margin: '5px',
//                         cursor: 'pointer',
//                         backgroundColor: jobCount > 0 ? '#ffcc80' : '#f5f5f5',
//                         borderRadius: '50%',
//                     }}
//                 >
//                     <Typography variant="h6">{i}</Typography>
//                     {jobCount > 0 && (
//                         <Box sx={{
//                             backgroundColor: '#0079ae',
//                             color: '#fff',
//                             borderRadius: '50%',
//                             width: '30px',
//                             height: '30px',
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             marginTop: '10px',
//                         }}>
//                             {jobCount}
//                         </Box>
//                     )}
//                 </Box>
//             );
//         }
//         return days;
//     };

//     const handleNextMonth = () => {
//         if (currentMonth === 11) {
//             setCurrentMonth(0);
//             setCurrentYear(currentYear + 1);
//         } else {
//             setCurrentMonth(currentMonth + 1);
//         }
//     };

//     const handlePreviousMonth = () => {
//         if (currentMonth === 0) {
//             setCurrentMonth(11);
//             setCurrentYear(currentYear - 1);
//         } else {
//             setCurrentMonth(currentMonth - 1);
//         }
//     };

//     const handleToday = () => {
//         setCurrentMonth(new Date().getMonth());
//         setCurrentYear(new Date().getFullYear());
//     };

//     return (
//         <div style={{ padding: '20px' }}>
//             <Button
//                 variant="contained"
//                 sx={{ mb: 2, background: "#0079ae", color: '#fff', '&:hover': { background: '#005f8d' } }}
//             >
//                 Create Job
//             </Button>

//             <Typography variant="h6" sx={{ mb: 2 }}>
//                 Assigned Jobs for {moment([currentYear, currentMonth]).format('MMMM YYYY')}
//             </Typography>

//             {/* Navigation Buttons */}
//             <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
//                 <Button onClick={handlePreviousMonth} variant="outlined">Back</Button>
//                 {/* <Button onClick={handleToday} variant="contained">Today</Button> */}
//                 <Button onClick={handleNextMonth} variant="outlined">Next</Button>
//             </Box>

//             {/* Calendar Layout */}
//             <Box sx={{
//                 display: 'grid',
//                 gridTemplateColumns: 'repeat(7, 1fr)',
//                 gridGap: '10px',
//                 paddingBottom: '20px',
//             }}>
//                 {renderCalendarDays()}
//             </Box>

//             {/* Jobs for Selected Date */}
//             {selectedDate && (
//                 <Box>
//                     <Typography variant="h6">
//                         Jobs on {moment(selectedDate).format('MMMM Do, YYYY')}
//                     </Typography>
//                     {selectedJobs.length > 0 ? (
//                         <Box sx={{ mt: 2 }}>
//                             {selectedJobs.map(job => (
//                                 <Box
//                                     key={job.id}
//                                     sx={{
//                                         background: '#ffffff',
//                                         p: 3,
//                                         mb: 2,
//                                         borderRadius: 2,
//                                         boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
//                                         borderLeft: '4px solid #0079ae',
//                                     }}
//                                 >
//                                     <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
//                                         Job Title: <span style={{ color: '#0079ae' }}>{job.job.job_title}</span>
//                                     </Typography>
//                                     <Typography variant="body1" sx={{ mb: 1 }}>
//                                         Assigned To: <span style={{ fontWeight: '500', color: '#333' }}>{job.worker.username}</span>
//                                     </Typography>
//                                     <Typography variant="body1" sx={{ mb: 1 }}>
//                                         Assigned Date: {moment(job.assigned_date).format('MMMM Do, YYYY')}
//                                     </Typography>
//                                 </Box>
//                             ))}
//                         </Box>
//                     ) : (
//                         <Typography>No jobs assigned on this date.</Typography>
//                     )}
//                 </Box>
//             )}
//         </div>
//     );
// };

// export default JobAssignmentsCalendar;
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
};

const JobAssignmentsCalendar = ({ token }) => {
    const [jobsByDate, setJobsByDate] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedJobs, setSelectedJobs] = useState([]);
    
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        fetchAssignedJobs();
    }, [currentMonth, currentYear]);

    const fetchAssignedJobs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/job-assigned`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
        
            const jobs = response.data;
            const jobsGroupedByDate = {};

            jobs.forEach(job => {
                const assignedDate = moment(job.assigned_date).format('YYYY-MM-DD');
                if (!jobsGroupedByDate[assignedDate]) {
                    jobsGroupedByDate[assignedDate] = [];
                }
                jobsGroupedByDate[assignedDate].push(job);
            });

            setJobsByDate(jobsGroupedByDate);
        } catch (error) {
            console.error("Error fetching assigned jobs:", error);
        }
    };

    const handleDateClick = (date) => {
        const dateStr = moment(date).format('YYYY-MM-DD');
        setSelectedJobs(jobsByDate[dateStr] || []);
        setSelectedDate(date);
    };

    const renderCalendarDays = () => {
        const days = [];
        const totalDays = daysInMonth(currentMonth, currentYear);

        for (let i = 1; i <= totalDays; i++) {
            const dateStr = moment([currentYear, currentMonth, i]).format('YYYY-MM-DD');
            const jobCount = jobsByDate[dateStr] ? jobsByDate[dateStr].length : 0;

            days.push(
                <Box
                    key={i}
                    onClick={() => handleDateClick(new Date(currentYear, currentMonth, i))}
                    sx={{
                        width: '90px',
                        height: '90px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #ddd',
                        margin: '5px',
                        cursor: 'pointer',
                        backgroundColor: jobCount > 0 ? '#FF9800' : '#f5f5f5',
                        borderRadius: '50%',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.05)',
                            backgroundColor: jobCount > 0 ? '#FF7043' : '#f0f0f0',
                        },
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>{i}</Typography>
                    {jobCount > 0 && (
                        <Box sx={{
                            backgroundColor: '#0079ae',
                            color: '#fff',
                            borderRadius: '50%',
                            width: '28px',
                            height: '28px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}>
                            {jobCount}
                        </Box>
                    )}
                </Box>
            );
        }
        return days;
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const handlePreviousMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const handleToday = () => {
        setCurrentMonth(new Date().getMonth());
        setCurrentYear(new Date().getFullYear());
    };

    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <Button
                variant="contained"
                sx={{
                    mb: 3,
                    background: "#0079ae",
                    color: '#fff',
                    '&:hover': { background: '#005f8d' },
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    borderRadius: '25px',
                }}
            >
                Create Job
            </Button>

            <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', color: '#333' }}>
                Jobs for {moment([currentYear, currentMonth]).format('MMMM YYYY')}
            </Typography>

            {/* Navigation Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Button
                    onClick={handlePreviousMonth}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        color: '#0079ae',
                        borderColor: '#0079ae',
                        '&:hover': {
                            backgroundColor: '#0079ae',
                            color: '#fff',
                        },
                    }}
                >
                    Previous Month
                </Button>
                <Button
                    onClick={handleNextMonth}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        color: '#0079ae',
                        borderColor: '#0079ae',
                        '&:hover': {
                            backgroundColor: '#0079ae',
                            color: '#fff',
                        },
                    }}
                >
                    Next Month
                </Button>
            </Box>

            {/* Calendar Layout */}
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gridGap: '10px',
                paddingBottom: '20px',
            }}>
                {renderCalendarDays()}
            </Box>

            {/* Jobs for Selected Date */}
            {selectedDate && (
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#0079ae', mb: 2 }}>
                        Jobs on {moment(selectedDate).format('MMMM Do, YYYY')}
                    </Typography>
                    {selectedJobs.length > 0 ? (
                        <Box sx={{ mt: 2 }}>
                            {selectedJobs.map(job => (
                                <Box
                                    key={job.id}
                                    sx={{
                                        background: '#fff',
                                        p: 3,
                                        mb: 2,
                                        borderRadius: '8px',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                        borderLeft: '4px solid #0079ae',
                                    }}
                                >
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        Job Title: <span style={{ color: '#0079ae' }}>{job.job.job_title}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        Assigned To: <span style={{ fontWeight: '500', color: '#333' }}>{job.worker.username}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        Assigned Date: {moment(job.assigned_date).format('MMMM Do, YYYY')}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        Job Status: {job.job.jobstatus ? "Complete" : 'Pending'}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Typography sx={{ color: '#888' }}>No jobs assigned on this date.</Typography>
                    )}
                </Box>
            )}
        </div>
    );
};

export default JobAssignmentsCalendar;



