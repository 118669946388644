// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .dashboard-container {
    padding: 20px;
    background-color: #fafafa; /* Light background for contrast */
  }
  
  .dashboard-header {
    margin-bottom: 20px;
  }
  
  .card {
    transition: transform 0.3s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .dashboard-card {
    transition: transform 0.2s, box-shadow 0.2s; /* Add transition for smooth animation */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .dashboard-card:hover {
    transform: translateY(-5px); /* Move the card slightly up */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Dashbord.css"],"names":[],"mappings":";EACE;IACE,aAAa;IACb,yBAAyB,EAAE,kCAAkC;EAC/D;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,2CAA2C,EAAE,wCAAwC;IACrF,eAAe,EAAE,6BAA6B;EAChD;;EAEA;IACE,2BAA2B,EAAE,8BAA8B;IAC3D,wCAAwC,EAAE,wBAAwB;EACpE","sourcesContent":["\r\n  .dashboard-container {\r\n    padding: 20px;\r\n    background-color: #fafafa; /* Light background for contrast */\r\n  }\r\n  \r\n  .dashboard-header {\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .card {\r\n    transition: transform 0.3s;\r\n  }\r\n  \r\n  .card:hover {\r\n    transform: scale(1.05);\r\n  }\r\n  \r\n  .dashboard-card {\r\n    transition: transform 0.2s, box-shadow 0.2s; /* Add transition for smooth animation */\r\n    cursor: pointer; /* Change cursor to pointer */\r\n  }\r\n  \r\n  .dashboard-card:hover {\r\n    transform: translateY(-5px); /* Move the card slightly up */\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
