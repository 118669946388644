import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Modal, Autocomplete } from '@mui/material';
import LeafletMap from '../../Component/Map/LeafletMap'; 
import axios from 'axios';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    height: '100vh',
};

const EditJobModal = ({ open, onClose, job, onJobUpdated ,token}) => {
    const [updatedTask, setUpdatedTask] = useState({ ...job });
    const [suggestions, setSuggestions] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(null);

    useEffect(() => {
        setUpdatedTask({ ...job });
    }, [job]);

    const fetchSuggestions = async (query) => {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: { 
                    q: `${query}, West Bengal`, 
                    format: 'json', 
                    addressdetails: 1 
                },
            });
            setSuggestions(response.data.map(item => ({
                label: item.display_name,
                lat: parseFloat(item.lat),
                lng: parseFloat(item.lon),
            })));
        } catch (error) {
            console.error('Error fetching location suggestions:', error);
        }
    };

    const handleLocationSelect = (event, value) => {
        if (value) {
            reverseGeocode(value.lat, value.lng);
        }
    };

    const reverseGeocode = async (lat, lng) => {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
                params: { lat, lon: lng, format: 'json', addressdetails: 1 },
            });
            const { address } = response.data;
            setUpdatedTask({
                ...updatedTask,
                latitude: lat,
                longitude: lng,
                location: `${address.suburb || ''}, `,
                road: address.road || '',
                city: address.city || address.town,
                postcode: address.postcode || '',
                area: address.county || address.state,
            });
            setSelectedLocation({ lat, lng });
        } catch (error) {
            console.error('Error fetching location details:', error);
        }
    };

    const handleMapLocationSelect = (lat, lng) => {
        reverseGeocode(lat, lng);
    };

    const handleSubmit = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}apiv1/job/${job.id}`, updatedTask, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            onJobUpdated(); // Notify parent component to refresh jobs list
            onClose(); // Close the modal
        } catch (error) {
            console.error('Error updating job:', error);
        }
    };

    return (
        <Box>
            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2">
                        Edit Job
                    </Typography>
                    <TextField
                        label="Job Title"
                        variant="outlined"
                        fullWidth
                        value={updatedTask.job_title}
                        onChange={(e) => setUpdatedTask({ ...updatedTask, job_title: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Instruction"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={updatedTask.instruction}
                        onChange={(e) => setUpdatedTask({ ...updatedTask, instruction: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <Autocomplete
                        freeSolo
                        options={suggestions}
                        getOptionLabel={(option) => option.label}
                        onChange={handleLocationSelect}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Location"
                                variant="outlined"
                                fullWidth
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    fetchSuggestions(e.target.value);
                                }}
                                sx={{ mb: 2 }}
                            />
                        )}
                    />
                    <TextField
                        label="Location"
                        variant="outlined"
                        fullWidth
                        value={updatedTask.location}
                        onChange={(e) => setUpdatedTask({ ...updatedTask, location: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                      <TextField
                        label="Road"
                        variant="outlined"
                        fullWidth
                        value={updatedTask.road}
                        onChange={(e) => setUpdatedTask({ ...updatedTask, road: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={updatedTask.city}
                        onChange={(e) => setUpdatedTask({ ...updatedTask, city: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Postcode"
                        variant="outlined"
                        fullWidth
                        value={updatedTask.postcode}
                        onChange={(e) => setUpdatedTask({ ...updatedTask, postcode: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Area"
                        variant="outlined"
                        fullWidth
                        value={updatedTask.area}
                        onChange={(e) => setUpdatedTask({ ...updatedTask, area: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ mb: 2 }}>
                        <LeafletMap onLocationSelect={handleMapLocationSelect} selectedLocation={selectedLocation} />
                    </Box>
                    <Button variant="contained" color="#0079ae" onClick={handleSubmit}>
                        Update Job
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default EditJobModal;
