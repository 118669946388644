import React from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work'; // Icon for Job
import PeopleIcon from '@mui/icons-material/People'; // Icon for Driver
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; 
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
const drawerWidth = 240;

const icons = {
  
  Dashboard: <DashboardIcon sx={{ color: 'rgb(133, 144, 165)'}} />,
  Job : <WorkIcon sx={{ color: 'rgb(133, 144, 165)'}}/>,
  Driver : <PeopleIcon sx={{ color: 'rgb(133, 144, 165)'}}/>,
  BookingCalendar: <CalendarTodayIcon sx={{ color: 'rgb(133, 144, 165)'}}/>,
  Vehicles : <LocalShippingIcon sx={{ color: 'rgb(133, 144, 165)'}}/>,
  Support : <SupportAgentIcon sx={{color: 'rgb(133, 144, 165)'}}/>

};

const Sidebar = ({ tabs, mobileOpen, handleDrawerToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
  <>
  
  {mobileOpen && (  <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#254336',
          color: '#fff',
          ...(isMobile && { position: 'absolute' }),
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {tabs.map((tab, index) => (
            <ListItem
              button
              component={Link}
              to={tab.path}
              key={index}
              onClick={() => {
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
              sx={{
                
             fontFamily:'Inter Tight',
                '&:hover': {
                  backgroundColor: '#34495e',
                  transform: "translateY(-5px)"
                },
                padding: '10px 20px',
                color: '#fff',
                borderRadius: '4px',
                marginBottom: '8px',
                      cursor: "pointer",
                transition: "transform 0.2s box-shadow 0.2s"
              }}
            >
              <ListItemIcon sx={{ color: '#ecf0f1' }}>
                {icons[tab.name]}
              </ListItemIcon>
              <ListItemText
                primary={tab.name}
                primaryTypographyProps={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  fontFamily:'Inter Tight',
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>)}
  </>
  );
};

export default Sidebar;

