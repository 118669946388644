import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './Feedback.css';
import pfdlogo from '../asste/imgpsh_fullsize_anim.png';
import html2pdf from 'html2pdf.js';

const Feedback = ({ token }) => {
    const location = useLocation();
    const { JobId } = location.state || {};
    const [feedbackData, setFeedbackData] = useState(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);  // New state for image load tracking
    const pdfRef = useRef(null);

    // Fetch feedback data and signatures
    useEffect(() => {
       const fetchData = async () => {
    try {
        if (token) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/feedbacks/${JobId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Add the token here
                }
            });
            const feedbacks = response.data;
            

            // Fetch each signature
            const updatedFeedbacks = await Promise.all(feedbacks.map(fetchSignature));
            setFeedbackData(updatedFeedbacks);
        } else {
            console.log('token is missing');
        }
    } catch (error) {
        console.error('Error fetching feedback data:', error);
    }
};  if (JobId) {
            fetchData();
        }

    }, [JobId, token]);

    // Fetch the digital signature
    const fetchSignature = async (detail) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}images/${detail.client_digital_signature}`, {
               
                responseType: 'arraybuffer', // Fetch image as an arraybuffer
            });
            const base64String = Buffer.from(response.data, 'binary').toString('base64');
            const base64Url = `data:image/jpeg;base64,${base64String}`;
             // Log the URL to verify it
            return { ...detail, client_signature_url: base64Url }; // Store Base64 URL
        } catch (error) {
           
            return { ...detail, client_signature_url: '' }; // Fallback to empty string
        }
    };
    
    
    // Ensure images are fully loaded
    useEffect(() => {
        const checkImagesLoaded = () => {
            const images = document.querySelectorAll('img');
            const loadedImages = Array.from(images).filter(image => image.complete);
            if (loadedImages.length === images.length) {
                setImagesLoaded(true);
            }
        };

        // Listen for the load event on each image
        const images = document.querySelectorAll('img');
        images.forEach(image => {
            image.onload = checkImagesLoaded;
        });

        // Check initial load
        checkImagesLoaded();
    }, []);

    const downloadPDF = () => {
        if (imagesLoaded) {
            const element = pdfRef.current;

            const options = {
                margin: [0.25, 0.25],
                filename: 'feedback-data.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { 
                    scale: 2, 
                    useCORS: true, // Enable CORS
                },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            };

            html2pdf().from(element).set(options).save();
        } else {
            console.log('Images are still loading...');
        }
    };

    if (!feedbackData) {
        return <p>Loading...</p>;
    }

    return (
        <div className="containers">
            <div className="maincards" ref={pdfRef} style={{ width: '100%', overflow: 'hidden' }}>
                {feedbackData.map((detail, index) => (
                    <div key={index} className="cards" style={{ pageBreakInside: 'avoid', fontSize: '10px', lineHeight: '1' }}>
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                            <img src={pfdlogo} alt="Logo" width="200px" />
                            <h2 style={{ borderBottom: '2px solid #142587', margin: '10px', paddingBottom: '20px', fontFamily: 'Inter Tight' }}>
                                Document Shredding - Electronic Equipment Recycling
                            </h2>
                        </div>

                        <div className='frist_col'>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <p style={{ margin: '5px 0', fontFamily: 'Inter Tight', fontSize: '15px' }}>
                                    Fairymount, Castlerea, Co Roscommon
                                </p>
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <div>
                                    <p style={{ margin: '5px 0', fontFamily: 'Inter Tight', fontSize: '15px' }}>
                                        <b style={{ color: '#142587' }}>Tel:</b> 1800987220 <b style={{ color: '#142587' }}>Email:</b> info@allstarshredding.ie <b style={{ color: '#142587' }}>Web:</b> www.allstarshredding.ie
                                    </p>
                                </div>
                            </div>
                            <h1 style={{ display: 'flex', width: '100%', justifyContent: 'center', color: '#142587' }}>
                                Waste Collection Docket
                            </h1>
                        </div>

                        <div>
                            <div className='frist_section'>
                                <div>
                                    <div className='row'>
                                        <p className='title'>Customer Name:</p><p className='bottomborder'>{detail.customer_name}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='title'>Customer Address:</p><p className='bottomborder'>{detail.customer_address}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='title'>Town/City:</p><p className='bottomborder'>{detail.city}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='title'>County:</p><p className='bottomborder'>{detail.county}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='title'>Eire Code:</p><p className='bottomborder'>{detail.eire_code}</p>
                                    </div>
                                   

                                </div>

                                <div>
                              
                                    <div className='row'>
                                        <p className='title'>Date of Destruction:</p><p className='bottomborder'>{detail.date_of_destruction}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='title'>Total Tonnes/Kg:</p><p className='bottomborder'>{detail.total_tonnes_kg}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='title'>Weight Docket No:</p><p className='bottomborder'>{detail.weight_docket_no}</p>
                                    </div>
                                  
                                    
                                    <div className='row'>
                                        <p className='title'>Message:</p><p className='bottomborder'>{detail.meassge}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            width: '100%',
                        }}>
                            <div className='detelisofmaterials'>
                                <h1 style={{ display: 'flex', width: '100%', justifyContent: 'center', color: '#142587', paddingTop: '2px' }}>
                                    Details of Materials Collected
                                </h1>
                                <div className='consolediv'>
                                    <div className='consolebox'>
                                        <p className='title'>120LTRBIN</p>
                                        <div className='consoleminibox'>{detail.ltrbin120 || '0'}</div>
                                    </div>
                                    <div className='consolebox'>
                                        <p className='title'>240LTRBIN</p>
                                        <div className='consoleminibox'>{detail.ltrbin240 || '0'}</div>
                                    </div>
                                    <div className='consolebox'>
                                        <p className='title'>BAG</p>
                                        <div className='consoleminibox'>{detail.bag || '0'}</div>
                                    </div>
                                    <div className='consolebox'>
                                        <p className='title'>BOX</p>
                                        <div className='consoleminibox'>{detail.box || '0'}</div>
                                    </div>
                                    <div className='consolebox'>
                                        <p className='title'>IT EQUIPMENT</p>
                                        <div className='consoleminibox'>{detail.it_equipment || '0'}</div>
                                    </div>
                                </div>
                                <div className='colorbox'>
                                    <h1 style={{ display: 'flex', width: '100%', justifyContent: 'center', color: 'white' }}>
                                        Certificate of Destruction
                                    </h1>
                                    <h2 style={{ display: 'flex', width: '100%', justifyContent: 'center', color: 'white', fontFamily: "Cookie, cursive", fontWeight: 400, fontSize: '20px', textAlign: 'center', maxWidth: '90%', margin: '0 auto',letterSpacing:'2px' }}>
                                        This is to certify that the above-mentioned confidential material has been destroyed through our security operation.
                                    </h2>
                                </div>
                            </div>
                        </div>
                       
                        <div className='lastdiv'>
                        <div style={{display:'flex',flexDirection:'row',width:'100%',marginLeft:'10%'}}>    <p className='title'>Customer Representative Name:</p><p className='bott'>{detail.customer_representative_name}</p></div>
                        <div className='lastdivs'>

                            <div className='imagewithsing'>
                        
                                   
                          
                                <p className='title'>Customer Signature:</p>
                                <div className='imagebox'>
                                    <img style={{ objectFit: 'contain', height: '80%',marginTop:'10px' }} src={`${process.env.REACT_APP_API_URL}images/${detail.client_digital_signature}`} width={'80%'} alt="Signature" />
                                </div>
                            </div>
                            <div className='certifactiwithvhecail'>
                                <div className='row'>
                                    <p className='title'>Certified by:</p><p className='bottomborder'>allstarshredding</p>
                                </div>
                                <div className='row'>
                                    <p className='title'>Vehicle Reg No:</p><p className='bottomborder'>{detail.vehicle}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                      
                    </div>
                    
                ))}
            </div>

            <div style={{ marginTop: '20px' }}>
                <button className="btn" onClick={downloadPDF}>Download PDF</button>
            </div>
        </div>
    );
};

export default Feedback;
