// src/Pages/Homepage.js
import React, { useState } from 'react';
import { Box, Toolbar } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../Component/Navbars/Sidebar';
import Navbar from '../Component/Navbars/Navbar';
import Dashboard from '../Pages/Dashbord';
import Job from './Job'; // Import the Task component
import Workers from './Workers';
import WorkerPage from './WorkerPage';
import JobAssignmentsCalendarr from './JobAssignmentsCalendar'
import Feedback from './Feedback';
import Vehicles from './Vehicles';
import Support from './Support';
import Job_modal from '../Component/Job_Modal/Job_modal';
const Homepage = ({  fetchToken,token, roll }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const tabs = [
    { name: 'Dashboard', path: 'dashboard' },
    { name: 'Job', path: 'jobs' },
    {name : 'Driver',path : "workers"},       // Add the new task tab
    {name: "BookingCalendar",path : "calendar"},
    {name: "Vehicles",path:"vehicles"},
    {name : "Support",path:"support"}
    
  
  ];


  const renderRoutes = () => {
    return (
      <Routes>
        <Route path="dashboard" element={<Dashboard token={token} />} />
        <Route path="jobs" element={<Job token={token} />} />
        <Route path="workers" element={<Workers token={token} />} />
        <Route path="workerpage" element={<WorkerPage token={token} />} />
        <Route path="vehicles" element={<Vehicles token={token}/>}/>
        <Route path='feedback' element={<Feedback token={token}/>}/>
        <Route path="support" element={<Support token={token}/>}/>
        <Route path='create/new/job' element={<Job_modal token={token}/>}/>
        <Route path="calendar" element={<JobAssignmentsCalendarr token={token} />} />  {/* Add the new task route */}
      </Routes>
    );
  };

  return (
    <Box sx={{ display: 'flex'  ,bgcolor: '#F5F5F7',minHeight:'100vh'}}>
      {/* Sidebar Drawer */}
      <Sidebar
        tabs={tabs}
        handleTabChange={() => {}}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F5F5F7', p: 3 }}>
        {/* AppBar for Page Header */}
        <Navbar handelToggel={handleDrawerToggle} fetchToken={fetchToken} />
        <Toolbar />

        {/* Page content for each tab based on routes */}
        {renderRoutes()}
      </Box>
    </Box>
  );
};

export default Homepage;
