import React from "react";
import { useState,useEffect } from "react";
import { Box, Button, TextField, Typography, Link,Snackbar,Alert } from '@mui/material';
import logo from '../asste/lolo.png'
// import icon from '../assets/icon.png'
import './Landingpage.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Landing_page = ({fetchToken,token,fetchroll,roll})=>{
    const [isLoginOpen, setLoginOpen] = useState(false);
    const [isSignupOpen, setSignupOpen] = useState(false);
    const [isCreateRestaurantOpen, setCreateRestaurantOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
  
  
  const handeluserlogin = async()=>{
    if(!email || !password){
  setError("Email and Password is requried")
    }
    try{
      const response = await axios.post(`${process.env.REACT_APP_API_URL}apiv1/admin/login`,{
        email : email,
        password : password
      })
      // console.log(response.data)
      fetchToken(response.data.token)
      fetchroll(response.data.roll)
    
     
    }
    catch(error){
    
      setError(error.message + '  Email and Password Invalide')
    }
  }
const nav = useNavigate();

    
  
    return (
      <div className="landingpage">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            background: 'url(/path-to-your-background-image.jpg) no-repeat center center fixed',
            backgroundSize: 'cover',
          }}
        >
          <Box
            sx={{
              width: 350,
              padding: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <Box sx={{ textAlign: 'center', marginBottom: 2 }}>

            </Box>
  
         <img src={logo} width={'100%'}/>
  
            <Typography align="center" sx={{ marginY: 2 }}>
              Admin Login
            </Typography>
  
            <TextField 
          label="Email" 
          fullWidth 
          margin="normal" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
           <TextField 
          label="Password" 
          type="password" 
          fullWidth 
          margin="normal" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
  
            <Button 
              variant="contained" 

              fullWidth 
              sx={{ mt: 2 ,backgroundColor:"#0079ae"}}
              onClick={() => handeluserlogin()}
            >
              Sign In
            </Button>
  
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>

              {/* <Link 
                href="#" 
                variant="body2"
                onClick={() => setSignupOpen(true)}
              >
                 Sing Up
              </Link> */}
            </Box>
          </Box>
        </Box>
        {error && (
        <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
        {/* Modals */}
     
      </div>
    );
  }
export default Landing_page
