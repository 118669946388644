import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './Pages/Landing_page';
import Dashbord from './Pages/Dashbord';
import Homepage from './Pages/Homepage';

function App() {
  const [token, setToken] = useState(null);
  const [roll, setRoll] = useState(null);

  useEffect(() => {
    // Fetch roll or any other setup when token is set
  }, [token]);

  return (
    <Router>
      <Routes>
        {/* Redirect to Homepage if token is present, otherwise show LandingPage */}
        <Route 
          path="/" 
          element={token ? <Navigate to={`/dashboard`} replace /> : <LandingPage fetchToken={setToken} fetchroll={setRoll} />} 
        />
        {/* Conditional rendering of routes based on token presence */}
        <Route 
          path={token ? `/*` : '/*'} 
          element={token ? <Homepage token={token} fetchToken={setToken} roll={roll} /> : <Navigate to="/" replace />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
